import { SeoProps } from "../../../composables/types";

type Route = {
  url: string;
};

export const getHeaderData = (seo: SeoProps | undefined, route: Route) => {
  if (!seo) {
    return undefined;
  }
  const meta = [
    {
      hid: "og:title",
      name: "og:title",
      content: seo.metaTitle,
    },
    {
      hid: "og:site_name",
      name: "og:site_name",
      content: "Art Mindfully",
    },
  ];

  const link = [];
  const canonical = route.url;

  meta.push({
    hid: "og:url",
    name: "og:url",
    content: canonical,
  });
  link.push({ rel: "canonical", href: canonical });

  const hrefLangDefault = {
    rel: "alternate",
    hreflang: "x-default",
    href: route.url,
  };
  link.push(hrefLangDefault);

  const hrefLangAU = {
    rel: "alternate",
    hreflang: "en-AU",
    href: route.url,
  };
  link.push(hrefLangAU);

  if (seo.metaDescription) {
    meta.push(
      {
        hid: "description",
        name: "description",
        content: seo.metaDescription,
      },
      {
        hid: "og:description",
        name: "og:description",
        content: seo.metaDescription,
      }
    );
  }
  if (seo.metaImage) {
    meta.push({
      hid: "og:image",
      name: "og:image",
      content: seo.metaImage.url,
    });
  }

  return {
    title: seo.metaTitle,
    meta,
    link,
  };
};
