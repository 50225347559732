import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/static/icons/WOMAN_IN_BLUE_HOME.jpeg'
import _imports_1 from '@/static/icons/SMALL_DOWN_ARROW.svg'


const _hoisted_1 = {
  key: 0,
  class: "home"
}
const _hoisted_2 = {
  id: "start",
  class: "home__introduction-wrapper"
}
const _hoisted_3 = { class: "home__introduction-content" }
const _hoisted_4 = { class: "home__primer-wrapper" }
const _hoisted_5 = { class: "home__intro-details-wrapper" }
const _hoisted_6 = { class: "home__intro-description" }
const _hoisted_7 = { class: "home__explore-card-wrapper" }
const _hoisted_8 = { class: "home__gallery-list" }
const _hoisted_9 = { class: "home__about-list" }
const _hoisted_10 = { class: "home__coming-soon-list" }
const _hoisted_11 = { class: "home__feedback-wrapper" }
const _hoisted_12 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallPlayer = _resolveComponent("SmallPlayer")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GalleryCard = _resolveComponent("GalleryCard")!

  return (_ctx.home)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "home__landing-wrapper" }, [
          _createElementVNode("div", { class: "home__landing-section" }, [
            _createElementVNode("img", {
              class: "home__painting",
              src: _imports_0,
              alt: "home page background"
            }),
            _createElementVNode("a", {
              id: "start-button",
              href: "#explore",
              class: "home__start-button",
              role: "button"
            }, [
              _createElementVNode("span", { class: "home__start" }, "Start")
            ]),
            _createElementVNode("span", { class: "home__whats-this" }, "What's this all about")
          ]),
          _createElementVNode("div", { class: "home__arrow-cta" }, [
            _createElementVNode("img", {
              class: "home__arrow",
              src: _imports_1,
              alt: "arrow that points down"
            })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "home__intro-header" }, "An intimate and personal way to connect with artworks from around the world.", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_SmallPlayer, { primer: _ctx.primer }, null, 8, ["primer"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_Markdown, {
                  source: _ctx.home.description
                }, null, 8, ["source"])
              ]),
              _createVNode(_component_router_link, {
                class: "home__learn-more",
                to: "/gallery/learn"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Learn More")
                ])),
                _: 1
              })
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { id: "explore" }, null, -1)),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "home__explore-heading-wrapper" }, [
              _createElementVNode("h1", { class: "home__explore" }, "Explore")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "home__explore-dt" }, "Explore", -1)),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.galleries, (gallery, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "home__gallery-item-wrapper"
              }, [
                _createVNode(_component_GalleryCard, {
                  gallery: gallery,
                  type: "gallery"
                }, null, 8, ["gallery"])
              ]))
            }), 128))
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "home__about-dt" }, "Learn", -1)),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aboutPages, (gallery, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "home__about-item-wrapper"
              }, [
                _createVNode(_component_GalleryCard, {
                  gallery: gallery,
                  type: "about"
                }, null, 8, ["gallery"])
              ]))
            }), 128))
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "home__coming-soon-dt" }, "Coming Soon", -1)),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comingSoon, (gallery, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "home__coming-soon-item-wrapper"
              }, [
                _createVNode(_component_GalleryCard, {
                  gallery: gallery,
                  type: "coming soon"
                }, null, 8, ["gallery"])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("a", {
            class: "home__feedback-link",
            target: "_blank",
            href: _ctx.GENERAL_FEEDBACK_URL
          }, "Give Feedback", 8, _hoisted_12)
        ])
      ]))
    : _createCommentVNode("", true)
}