
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { NAVIGATION_LINKS } from "@/constants/navigationLinks";

export default defineComponent({
  name: "Header",
  props: {
    toggleOpenMenu: Function,
  },
  setup() {
    const router = useRouter();

    const goHome = () => {
      router.push({ path: "/" });
    };

    return {
      goHome,
      NAVIGATION_LINKS,
    };
  },
});
