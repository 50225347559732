import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../static/icons/ART_MINDFULLY_BLACK.svg'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__link-wrapper" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "header__link" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "header__link" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "header__link" }
const _hoisted_9 = { class: "header__menu-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header__logo-wrapper",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("img", {
        class: "header__main-logo",
        src: _imports_0,
        alt: "art mindfully"
      }, null, -1)
    ])),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: "header__link",
        href: _ctx.NAVIGATION_LINKS[0].href,
        target: "_blank"
      }, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.NAVIGATION_LINKS[0].title), 1)
      ], 8, _hoisted_3),
      _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "header__link--divider" }, "I", -1)),
      _createElementVNode("a", {
        class: "header__link",
        href: _ctx.NAVIGATION_LINKS[1].href,
        target: "_blank"
      }, [
        _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.NAVIGATION_LINKS[1].title), 1)
      ], 8, _hoisted_5),
      _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "header__link--divider" }, "I", -1)),
      _createElementVNode("a", {
        class: "header__link",
        href: _ctx.NAVIGATION_LINKS[2].href,
        target: "_blank"
      }, [
        _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.NAVIGATION_LINKS[2].title), 1)
      ], 8, _hoisted_7)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_v_icon, {
        class: "header__menu-icon",
        icon: "$menu",
        onClick: _withModifiers(_ctx.toggleOpenMenu, ["stop"])
      }, null, 8, ["onClick"])
    ])
  ]))
}