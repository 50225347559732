import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../static/icons/ART_MINDFULLY_WHITE.svg'


const _hoisted_1 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"footer__icons-wrapper\"><img class=\"footer__main-logo\" src=\"" + _imports_0 + "\" alt=\"art mindfully\"></div><div class=\"footer__link-wrapper\"><a class=\"footer__explore-link\" href=\"https://mindfully.art/contact-details\" target=\"_blank\"><h4 class=\"footer__link\">Subscribe</h4></a><a class=\"footer__explore-link\" href=\"/#explore\"><h4 class=\"footer__link\">Explore</h4></a></div><div class=\"footer__copyright-wrapper\"><span class=\"footer__copyright\">© Art Mindfully 2025</span></div>", 3)
  ])))
}