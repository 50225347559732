import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createHead } from "@vueuse/head";
import VueLazyLoad from "vue3-lazyload";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";

const head = createHead();

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", // Ensure MDI is the default icon set
    aliases,
    sets: {
      mdi,
    },
  },
});

createApp(App)
  .use(router)
  .use(head)
  .use(VueLazyLoad, {
    loading: "../img/HOME_HERO_GRADIENT.7e3c0b41.jpg",
  })
  .use(vuetify)
  .mount("#app");
