
import { defineComponent, ref } from "vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import NavigationDrawer from "@/components/Navigation/NavigationDrawer.vue";

export default defineComponent({
  name: "App",
  components: {
    Footer,
    Header,
    NavigationDrawer,
  },
  setup() {
    const openMenu = ref(false);

    const toggleOpenMenu = () => {
      openMenu.value = !openMenu.value;
    };

    return {
      openMenu,
      toggleOpenMenu,
    };
  },
});
