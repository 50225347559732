import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/static/icons/DOWN_ARROW.svg'


const _hoisted_1 = {
  key: 0,
  class: "gallery"
}
const _hoisted_2 = { class: "gallery__header-title" }
const _hoisted_3 = { class: "gallery__header-image-wrapper" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "gallery__header-info-content" }
const _hoisted_6 = { class: "gallery__sub-title-wrapper" }
const _hoisted_7 = { class: "gallery__sub-title" }
const _hoisted_8 = { class: "gallery__location" }
const _hoisted_9 = { class: "gallery__description" }
const _hoisted_10 = {
  key: 0,
  class: "gallery__primer-section"
}
const _hoisted_11 = { class: "gallery__primer-description-wrapper" }
const _hoisted_12 = { class: "gallery__primer-description-details" }
const _hoisted_13 = { class: "gallery__primer-wrapper" }
const _hoisted_14 = {
  key: 0,
  class: "gallery__main-wrapper"
}
const _hoisted_15 = { class: "gallery__begin-wrapper" }
const _hoisted_16 = { class: "gallery__segment-grid" }
const _hoisted_17 = { class: "gallery__artworks-heading" }
const _hoisted_18 = { class: "gallery__segment-list-wrapper" }
const _hoisted_19 = { class: "gallery__authors-wrapper" }
const _hoisted_20 = { class: "gallery__our-authors" }
const _hoisted_21 = { class: "gallery__authors-description" }
const _hoisted_22 = { class: "gallery__author-list-wrapper" }
const _hoisted_23 = { class: "gallery__feedback-wrapper" }
const _hoisted_24 = ["href"]
const _hoisted_25 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_SmallPlayer = _resolveComponent("SmallPlayer")!
  const _component_SegmentCard = _resolveComponent("SegmentCard")!
  const _component_AuthorCard = _resolveComponent("AuthorCard")!

  return (_ctx.gallery)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.gallery.title), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "gallery__header-image",
            src: _ctx.gallery.headerImage.url,
            alt: _ctx.gallery.headerImage.description
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", {
          class: "gallery__header-info-wrapper",
          style: _normalizeStyle(_ctx.primerProps ? 'padding-bottom:72px;' : 'padding-bottom:4px;')
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.gallery.subTitle), 1),
              _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.gallery.location), 1)
            ]),
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_Markdown, {
                breaks: true,
                source: _ctx.gallery.description
              }, null, 8, ["source"])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "gallery__divider" }, null, -1)),
            (_ctx.primerProps)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "gallery__primer-description-title" }, "Intro", -1)),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.primerDescription), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_SmallPlayer, { primer: _ctx.primerProps }, null, 8, ["primer"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 4),
        (_ctx.segments.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("span", {
                  style: _normalizeStyle(_ctx.primerProps ? 'margin-top:80px' : 'margin-top:40px'),
                  class: "gallery__introduction"
                }, "Now, let's begin.", 4),
                _cache[2] || (_cache[2] = _createElementVNode("img", {
                  class: "gallery__arrow",
                  src: _imports_0,
                  alt: "An arrow pointing down the page"
                }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("h1", _hoisted_17, _toDisplayString(_ctx.contentHeading), 1),
                _createElementVNode("div", _hoisted_18, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segments, (segment, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "gallery__segment-item",
                      key: index
                    }, [
                      _createVNode(_component_SegmentCard, { segment: segment }, null, 8, ["segment"])
                    ]))
                  }), 128))
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("h2", _hoisted_20, "Our " + _toDisplayString(_ctx.authorHeading), 1),
          _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.authorText), 1),
          _createElementVNode("div", _hoisted_22, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authors, (author, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "gallery__author-list",
                key: index
              }, [
                _createVNode(_component_AuthorCard, { author: author }, null, 8, ["author"])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("a", {
            class: "gallery__feedback-link",
            target: "_blank",
            href: _ctx.GENERAL_FEEDBACK_URL
          }, "Give Feedback", 8, _hoisted_24),
          _createElementVNode("a", {
            class: "gallery__support-link",
            target: "_blank",
            href: _ctx.SUPPORT_US_URL
          }, "Become an Illuminary, support us here", 8, _hoisted_25)
        ])
      ]))
    : _createCommentVNode("", true)
}