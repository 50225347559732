import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/static/icons/info-circle.svg'


const _hoisted_1 = {
  key: 0,
  class: "segment-page__wrapper"
}
const _hoisted_2 = { class: "segment-page" }
const _hoisted_3 = { class: "segment-page__sub-title" }
const _hoisted_4 = { class: "segment-page__image-wrapper" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "segment-page__below-image-wrapper" }
const _hoisted_8 = { class: "segment-page__title-wrapper" }
const _hoisted_9 = { class: "segment-page__heading-wrapper" }
const _hoisted_10 = { class: "segment-page__title-tip" }
const _hoisted_11 = { class: "segment-page__player-wrapper" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "segment-page__zoom-wrapper" }
const _hoisted_14 = ["src", "alt"]
const _hoisted_15 = { class: "segment-page__zoom-close-icon-wrapper" }
const _hoisted_16 = {
  key: 0,
  class: "segment-page__loading-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_CustomModal = _resolveComponent("CustomModal")!
  const _component_LargePlayer = _resolveComponent("LargePlayer")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_ZoomImg = _resolveComponent("ZoomImg")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.segment)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.segment.title), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "segment-page__background-image",
                src: _ctx.segment.artworkImage.url,
                alt: _ctx.segment.artworkImage.description
              }, null, 8, _hoisted_5),
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShowFullSize && _ctx.toggleShowFullSize(...args)), ["stop"]))
              }, [
                _createElementVNode("img", {
                  class: "segment-page__main-image",
                  src: _ctx.segment.artworkImage.url,
                  alt: _ctx.segment.artworkImage.description
                }, null, 8, _hoisted_6)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createTextVNode(_toDisplayString(_ctx.segment.title) + " ", 1),
                    (_ctx.segment.artworkDescription)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "segment-page__tooltip",
                          src: _imports_0,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = true))
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleShowFullSize && _ctx.toggleShowFullSize(...args)))
                    }, [
                      _createVNode(_component_v_icon, {
                        class: "segment-page__zoom-icon",
                        icon: "mdi-magnify-plus",
                        color: "white"
                      })
                    ])
                  ])
                ]),
                _createElementVNode("span", null, _toDisplayString(_ctx.segment.artist.name) + " | " + _toDisplayString(_ctx.segment.year), 1)
              ])
            ]),
            (_ctx.segment.artworkDescription)
              ? (_openBlock(), _createBlock(_component_CustomModal, {
                  key: 0,
                  modelValue: _ctx.showModal,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showModal) = $event))
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.segment.title), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Markdown, {
                      source: _ctx.segment.artworkDescription,
                      breaks: true
                    }, null, 8, ["source"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_LargePlayer, { player: _ctx.audio }, null, 8, ["player"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[5] || (_cache[5] = [
          _createElementVNode("h3", null, "Loading...", -1)
        ]))),
    (_ctx.showFullSize && _ctx.segment)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 2,
          modelValue: _ctx.showFullSize,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showFullSize) = $event)),
          transition: "dialog-bottom-transition",
          fullscreen: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("img", {
                class: "segment-page__modal-background-image",
                src: _ctx.segment.artworkImage.url,
                alt: _ctx.segment.artworkImage.description
              }, null, 8, _hoisted_14),
              _createElementVNode("div", _hoisted_15, [
                _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "segment-page__zoom-text-tablet" }, "Tap & drag to zoom", -1)),
                _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "segment-page__zoom-text-desktop" }, "Click to zoom", -1)),
                _createVNode(_component_v_icon, {
                  class: "segment-page__zoom-close-icon",
                  icon: "mdi-close-circle-outline",
                  size: "30",
                  color: "white",
                  onClick: _withModifiers(_ctx.toggleShowFullSize, ["stop"])
                }, null, 8, ["onClick"])
              ]),
              (!_ctx.isLoaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_v_progress_circular, {
                      color: "primary",
                      indeterminate: "disable-shrink",
                      size: "48",
                      width: "4"
                    })
                  ]))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_ZoomImg, {
                  class: "segment-page__zoomable-image",
                  src: 
              _ctx.segment?.highResolutionArtworkImage?.url ||
              _ctx.segment.artworkImage.url
            ,
                  alt: 
              _ctx.segment?.highResolutionArtworkImage?.description ||
              _ctx.segment.artworkImage.description
            ,
                  "zoom-scale": 3,
                  "zoom-type": "drag",
                  onLoad: _ctx.setIsLoaded
                }, null, 8, ["src", "alt", "onLoad"])
              ], 512), [
                [_vShow, _ctx.isLoaded]
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}