<template>
  <v-navigation-drawer
    class="navigation-drawer"
    :width="240"
    location="right"
    v-model="isOpen"
  >
    <div class="navigation-drawer__drawer-content-wrapper">
      <div class="navigation-drawer__drawer-upper-content">
        <div class="navigation-drawer__close-menu-wrapper">
          <v-icon
            class="navigation-drawer__close-icon"
            icon="$close"
            size="24"
            @click.stop="toggleOpenMenu"
          ></v-icon>
        </div>
        <div class="navigation-drawer__navigation-drawer-link-wrapper">
          <a
            v-for="(link, index) in NAVIGATION_LINKS"
            :key="index"
            :href="link.href"
            class="navigation-drawer__nav-button"
            target="_blank"
          >
            <h4 class="header__link">{{ link.title }}</h4>
          </a>
        </div>
      </div>
      <div class="navigation-drawer__drawer-lower-content">
        <div class="navigation-drawer__copyright-wrapper">
          <span class="navigation-drawer__copyright"
            >&copy; Art Mindfully 2025</span
          >
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { computed } from "vue";
import { NAVIGATION_LINKS } from "@/constants/navigationLinks";

export default {
  name: "NavigationDrawer",
  props: {
    toggleOpenMenu: Function,
    openMenu: Boolean,
  },
  setup(props) {
    const isOpen = computed(() => {
      return props.openMenu;
    });
    return {
      isOpen,
      NAVIGATION_LINKS,
    };
  },
};
</script>

<style scoped lang="scss">
.navigation-drawer {
  padding: 0px;

  &__navigation-drawer-link-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 36px;
    row-gap: 12px;
  }

  &__drawer-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: theme-color(dusk);
    background-image: url("@/static/icons/HOME_HERO_GRADIENT.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__close-menu-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
  }

  &__close-icon {
    color: theme-color(pure-black);
  }

  &__nav-button {
    background-color: white;
    height: 36px;
    width: 100%;
    bottom: 0;
    border: 3px solid theme-color(pure-black);
    border-radius: 10px;
    box-shadow: 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    z-index: 999;

    &:hover {
      cursor: pointer;
    }
  }

  &__copyright-wrapper {
    padding: 12px;
  }

  &__copyright {
    color: theme-color(pure-black);
  }
}
</style>
