import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"
import _imports_0 from '../../static/icons/PLAY_BUTTON_DARK.svg'
import _imports_1 from '../../static/icons/PAUSE_BUTTON_DARK.svg'


const _hoisted_1 = { class: "large-player" }
const _hoisted_2 = { class: "large-player__control-wrapper" }
const _hoisted_3 = { class: "large-player__controls" }
const _hoisted_4 = { class: "large-player__text-content" }
const _hoisted_5 = { class: "large-player__title" }
const _hoisted_6 = { class: "large-player__below-title" }
const _hoisted_7 = { class: "large-player__audio-length" }
const _hoisted_8 = { class: "large-player__segment-author" }
const _hoisted_9 = {
  key: 0,
  class: "play-button",
  src: _imports_0,
  alt: "play button"
}
const _hoisted_10 = {
  key: 1,
  class: "pause-button",
  src: _imports_1,
  alt: "play button"
}
const _hoisted_11 = {
  ref: "audioPlayer",
  preload: "metadata"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "large-player__audio-player" }
const _hoisted_14 = { class: "large-player__current-time" }
const _hoisted_15 = { class: "large-player__slider" }
const _hoisted_16 = { class: "large-player__duration" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.player?.audioTitle), 1),
          _createElementVNode("span", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.player?.length), 1),
            _cache[2] || (_cache[2] = _createTextVNode(" · With ")),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.player?.author.name), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "large-player__button-wrapper",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePlay && _ctx.togglePlay(...args)))
        }, [
          (_ctx.paused)
            ? (_openBlock(), _createElementBlock("img", _hoisted_9))
            : (_openBlock(), _createElementBlock("img", _hoisted_10))
        ])
      ])
    ]),
    _createElementVNode("audio", _hoisted_11, [
      _createElementVNode("source", {
        src: _ctx.player?.audio.url,
        type: "audio/mp3"
      }, null, 8, _hoisted_12)
    ], 512),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formattedCurrentTime), 1),
      _createElementVNode("div", _hoisted_15, [
        (_openBlock(), _createBlock(_component_ProgressBar, {
          audioProgress: _ctx.value,
          key: _ctx.value,
          onChangePosition: _cache[1] || (_cache[1] = (newProgress) => _ctx.updatePosition(newProgress))
        }, null, 8, ["audioProgress"]))
      ]),
      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.formattedDuration), 1)
    ])
  ]))
}