import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/static/icons/ARROW_BUTTON_BLACK.svg'


const _hoisted_1 = { class: "gallery-card" }
const _hoisted_2 = { class: "gallery-card__image-wrapper" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "gallery-card__city-sticker" }
const _hoisted_5 = {
  key: 0,
  class: "gallery-card__coming-soon"
}
const _hoisted_6 = { class: "gallery-card__info-wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "gallery-card__details"
}
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: _ctx.slug }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: _normalizeClass(["gallery-card__image", { 'black-white': _ctx.type === 'coming soon' }]),
            src: _ctx.gallery.headerImage.url,
            alt: _ctx.gallery.headerImage.description || 'loading image'
          }, null, 10, _hoisted_3)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.stickerText), 1),
      (_ctx.type === 'coming soon')
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Coming Soon"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: _normalizeClass(["gallery-card__meditation-wrapper", 
          _ctx.type === 'coming soon'
            ? 'gallery-card__coming-soon-title-wrapper'
            : ''
        ])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["gallery-card__title", 
            _ctx.type === 'coming soon' ? 'gallery-card__coming-soon-title' : ''
          ])
        }, _toDisplayString(_ctx.gallery.title), 3),
        (_ctx.type !== 'coming soon')
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.totalSegments) + " " + _toDisplayString(_ctx.segmentTitle), 1),
              (_ctx.type === 'gallery')
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, " · " + _toDisplayString(_ctx.totalAuthors) + " " + _toDisplayString(_ctx.guideName), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.type !== 'coming soon')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "gallery-card__button-wrapper",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pushToGallery && _ctx.pushToGallery(...args)))
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              class: "arrow-button",
              src: _imports_0,
              alt: "play button"
            }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}