import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/static/icons/CLOSE_BLACK.svg'


const _hoisted_1 = { class: "modal__title-wrapper" }
const _hoisted_2 = { class: "modal__title" }
const _hoisted_3 = { class: "modal__content" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, _mergeProps(_ctx.$attrs, {
    classes: "modal-container",
    "content-class": "modal-content"
  }), {
    default: _withCtx(({ params, close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true),
          _renderSlot(_ctx.$slots, "link", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", { params: params }, undefined, true)
      ]),
      _createElementVNode("button", {
        class: "modal__close",
        onClick: close
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", { src: _imports_0 }, null, -1)
      ]), 8, _hoisted_4)
    ]),
    _: 3
  }, 16))
}