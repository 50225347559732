import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/static/icons/ARROW_BUTTON_WHITE.svg'


const _hoisted_1 = { class: "segment-card" }
const _hoisted_2 = { class: "segment-card__image-wrapper" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "segment-card__main-section" }
const _hoisted_5 = { class: "segment-card__title-wrapper" }
const _hoisted_6 = { class: "segment-card__title" }
const _hoisted_7 = { class: "segment-card__details" }
const _hoisted_8 = { class: "segment-card__cta-wrapper" }
const _hoisted_9 = { class: "segment-card__meditation-wrapper" }
const _hoisted_10 = { class: "segment-card__title" }
const _hoisted_11 = { class: "segment-card__details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: _ctx.slug }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: "segment-card__image",
            src: _ctx.segmentImage.url,
            alt: _ctx.segmentImage.description || 'loading image'
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.segment.title), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.segment.artist.name) + " | " + _toDisplayString(_ctx.segment.year), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("h4", _hoisted_10, _toDisplayString(_ctx.segment.meditationType), 1),
          _createElementVNode("span", _hoisted_11, [
            _createTextVNode(_toDisplayString(_ctx.segment.audio.length) + " · With ", 1),
            _createElementVNode("strong", null, _toDisplayString(_ctx.segment.audio.author.name), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "segment-card__button-wrapper",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pushToSegment && _ctx.pushToSegment(...args)))
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            class: "arrow-button",
            src: _imports_0,
            alt: "play button"
          }, null, -1)
        ]))
      ])
    ])
  ]))
}